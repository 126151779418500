<template>
    <div :style="{ width: '100%', height }"></div>
</template>

<script>
export default {
    name: 'PageSpacer',
    props: {
        height: {
            type: String,
            default: '2rem'
        }
    }
}
</script>