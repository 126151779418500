<template>
    <nav class="nav nav--bottom">
        <ul class="nav__list">
            <li
                v-for="link in links"
                :key="link"
                class="nav__item"
            >
                <router-link class="nav__link" :to="{ name: link.page }" active-class="nav__link--active">
                    {{ link.title }}
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'NavigationList',
    data() {
        return {
            links: [
                {
                    title: 'Home',
                    page: 'HomePage'
                },
                {
                    title: 'Bg Video',
                    page: 'BgVideoPage'
                }
            ]
        }
    }
}
</script>
