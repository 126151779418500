<template>
    <div class="select-room-screen">
        <label for="rooms">Select a room:</label>
        <select
            id="rooms"
            :value="selectedRoom"
            name="rooms"
            @input="(event) => setSelectedRoom(event.target.value)"
        >
            <option disabled selected value>
                Select a room
            </option>
            <option v-for="room in availableRooms" :key="room" :value="room">
                {{ room }}
            </option>
        </select>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'SelectRoomScreen',
    computed: {
        ...mapGetters(['selectedRoom', 'availableRooms']),
    },
    mounted() {
        // reset room on this page load
        this.selectRoom(null);
    },
    methods: {
        ...mapActions(['selectRoom']),
        setSelectedRoom(room) {
            this.selectRoom(room);
            this.$router.push({ name: 'StartScreen' });
        }
    },
}
</script>