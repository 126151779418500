<template>
    <div class="error-page">
        <p>Uuuups, something went wrong.</p>
        <router-link :to="{ name: 'StartScreen' }">
            Refresh
        </router-link>
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';

export default {
    name: 'NotFound',
    setup () {
        useMeta({
            title: 'Error'
        })
    }
}
</script>
