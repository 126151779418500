<template>
    <div class="survey-pagination">
        <div class="survey-pagination__steps">
            <span
                v-for="index in count"
                :key="index" 
                :class="['survey-pagination__step', index - 1 === current && 'survey-pagination__step--active']"
            ></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SurveyPagination',
    props: {
        count: {
            type: Number,
            default: 1
        },
        current: {
            type: Number,
            default: -1
        }
    }
}
</script>