import { createRouter, createWebHashHistory } from 'vue-router';
import PresentationLauncher from '@/launcher/PresentationLauncher';
import DispatcherStartScreen from '@/pages/dispatcher/StartScreen';
import DispatcherQuestionScreen from '@/pages/dispatcher/QuestionScreen';
import DispatcherSummaryScreen from '@/pages/dispatcher/SummaryScreen';
import ReceiverStartScreen from '@/pages/receiver/StartScreen';
import ReceiverQuestionScreen from '@/pages/receiver/QuestionScreen';
import ReceiverQuestionReviewScreen from '@/pages/receiver/QuestionReviewScreen';
import ReceiverSummaryScreen from '@/pages/receiver/SummaryScreen';
import SelectRoomScreen from '@/pages/SelectRoomScreen';
import store from '@/store';

// import HomePage from '@/pages/HomePage';
// import BgVideoPage from '@/pages/BgVideoPage';
import ErrorPage from '@/pages/ErrorPage';
import NotFound from '@/pages/NotFound';
import AnalyticsPage from '@/pages/AnalyticsPage';
// import PreviewPage from '@/pages/PreviewPage';

export const APP_MODES = {
    DISPATCHER: 'dispatcher',
    RECEIVER: 'receiver'
};

export const APP_MODE = process.env.VUE_APP_MODE || APP_MODES.DISPATCHER;

export function checkMode(mode) {
    return APP_MODE === mode;
}

const showLauncherPage = process.env.VUE_APP_SHOW_LAUNCHER_PAGE;

const baseRoutes = [
    ...(showLauncherPage
        ? [
            {
                path: '/',
                name: 'PresentationLauncher',
                component: PresentationLauncher,
                meta: {
                    showNavigation: true,
                    showHeader: false,
                    showFooter: false,
                    presentationLauncher: true
                }
            },
            {
                path: '/start',
                name: 'StartScreen',
                component: checkMode(APP_MODES.DISPATCHER)
                    ? DispatcherStartScreen
                    : ReceiverStartScreen,
                meta: {
                    showNavigation: false,
                    showHeader: false,
                    showFooter: false
                }
            }
        ]
        : [
            {
                path: '/',
                name: 'StartScreen',
                component: checkMode(APP_MODES.DISPATCHER)
                    ? DispatcherStartScreen
                    : ReceiverStartScreen,
                meta: {
                    showNavigation: false,
                    showHeader: false,
                    showFooter: false
                }
            }
        ]),
    {
        path: '/question/:questionIndex',
        name: 'QuestionScreen',
        component: checkMode(APP_MODES.DISPATCHER) ? DispatcherQuestionScreen : ReceiverQuestionScreen,
        meta: {
            showNavigation: false,
            showHeader: true,
            showFooter: true
        }
    },
    ...(checkMode(APP_MODES.RECEIVER) ? [
        {
            path: '/question-review/:questionIndex',
            name: 'QuestionReviewScreen',
            component: ReceiverQuestionReviewScreen,
            meta: {
                showNavigation: false,
                showHeader: true,
                showFooter: true
            }
        },
    ] : []),
    ...(checkMode(APP_MODES.RECEIVER) ? [
        {
            path: '/select-room',
            name: 'SelectRoomScreen',
            component: SelectRoomScreen,
            meta: {
                showNavigation: false,
                showHeader: false,
                showFooter: false,
            }
        },
    ] : []),
    {
        path: '/summary',
        name: 'SummaryScreen',
        component: checkMode(APP_MODES.DISPATCHER) ? DispatcherSummaryScreen : ReceiverSummaryScreen,
        meta: {
            showNavigation: false,
            showHeader: true,
            showFooter: false
        }
    },
    // {
    //     path: '/home',
    //     name: 'HomePage',
    //     component: HomePage
    // },
    // {
    //     path: '/bgvideo',
    //     name: 'BgVideoPage',
    //     component: BgVideoPage
    // },
    {
        path: '/error',
        name: 'ErrorPage',
        component: ErrorPage
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    },
    {
        path: '/analytics',
        name: 'AnalyticsPage',
        component: AnalyticsPage,
        meta: {
            presentationLauncher: true
        }
    },
    // {
    //     path: '/preview',
    //     name: 'PreviewPage',
    //     component: PreviewPage
    // }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes: baseRoutes,

    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
});

router.beforeEach((to) => {
    const selectedRoom = store.getters['selectedRoom'];
    if (checkMode(APP_MODES.RECEIVER) && to.name !== 'SelectRoomScreen' && !selectedRoom) {
        return { name: 'SelectRoomScreen' };
    }
})

export default router;
