<template>
    <teleport to="body">
        <div
            id="timeoutModal"
            ref="modal"
            role="dialog"
            class="modal modal--timeout"
        >
            <transition name="modal-mask">
                <div
                    v-show="modalOpen"
                    class="modal__mask"
                    @click="closeModal"
                ></div>
            </transition>

            <transition name="timeout-modal-body">
                <div
                    v-show="modalOpen"
                    class="modal__body modal__body--calliditas"
                >
                    <div class="modal__content">
                        <h2 class="h1">
                            Do you need more time?
                        </h2>
                        <div class="modal__countdown">
                            <span :style="{'width': countdownBarWidth}"></span>
                        </div>
                        <p>You haven't done anything in a while, would you like to continue?</p>
                    </div>
                    <div class="modal__footer">
                        <button class="button" aria-label="Close modal" @click="closeCountdown()">
                            Yes, continue
                        </button>
                    </div>
                </div>
            </transition>
        </div>
    </teleport>
</template>

<script>
export default {
    name: 'TimeoutModal',

    data () {
        return {
            timeoutValue: process.env.VUE_APP_TIMEOUT_VALUE,
            timeoutTimer: '',
            countdownDuration: 10,
            countdownValue: 10,
            countdownTimer: '',
            modalOpen: false,
            countdownBarWidth: '99%',
            videoPlaying: false
        }
    },

    computed: {
        currentRouteName() {
            return this.$route.name;
        },
    },

    watch: {
        videoPlaying(val) {
            if(val == true) {
                // If videoPlaying prop receives true from the parent, we clear the interval
                // so that the timeoutTimer will not count down when video is playing
                clearInterval(this.timeoutTimer);
            } else {
                // If it receives false, a video is not playing so we reset/restart the timer
                this.resetTimeoutTimer();
            }
        }
    },

    mounted () {
        this.emitter.on('video-active', () => {
            this.videoPlaying = true;
        });

        this.emitter.on('video-inactive', () => {
            this.videoPlaying = false;
        });

        window.addEventListener('click', this.resetTimeoutTimer);
        window.addEventListener('touch', this.resetTimeoutTimer);
        window.addEventListener('scroll', this.resetTimeoutTimer);

        // set timeout timer with delay cause if we navigate to the page directly via URL $route.name will be undefined
        setTimeout(() => {
            this.resetTimeoutTimer();
        }, 100)
    },

    deactivated() {
        window.removeEventListener('click', this.resetTimeoutTimer);
        window.removeEventListener('touch', this.resetTimeoutTimer);
        window.removeEventListener('scroll', this.resetTimeoutTimer);
    },

    methods: {
        createTimeoutTimer() {
            this.timeoutTimer = setInterval(function(){
                if (this.timeoutValue <= 0) {
                    clearTimeout(this.timeoutTimer);
                    this.modalOpen = true;
                    this.showCountdown();
                } else {
                    this.modalOpen = false;
                    this.timeoutValue--;
                    // TO DEBUG: Comment the below line back in
                    // console.log(this.timeoutValue);
                }
            }.bind(this), 1000, this.timeoutValue);
        },

        resetTimeoutTimer() {
            if (!this.modalOpen && !this.videoPlaying) {
                clearTimeout(this.timeoutTimer);
                this.timeoutValue = process.env.VUE_APP_TIMEOUT_VALUE;

                clearInterval(this.countdownTimer);
                this.countdownValue = this.countdownDuration;

                if (this.currentRouteName && this.currentRouteName !== 'PresentationLauncher' && this.currentRouteName !== 'StartScreen' && this.currentRouteName !== 'AnalyticsPage') {
                    this.createTimeoutTimer();
                }
            }
        },

        closeCountdown() {
            this.modalOpen = false;
            this.resetTimeoutTimer();
        },

        showCountdown() {
            this.countdownValue = this.countdownDuration;
            this.countdownTimer = setInterval(function(){
                if (this.countdownValue <= 0) {
                    clearInterval(this.countdownTimer);
                    this.backToHomePage();
                } else {
                    let barPerc = (100 / this.countdownDuration) * (this.countdownValue-1);
                    this.countdownBarWidth = barPerc + '%';
                }
                this.countdownValue--;
            }.bind(this), 1000, this.countdownValue);
        },

        backToHomePage() {
            this.modalOpen = false;
            clearInterval(this.countdownTimer);
            this.countdownValue = this.countdownDuration;
            this.countdownBarWidth = '99%';
            this.$router.push( {name: 'StartScreen' });
        }
    }
}
</script>
