<template>
    <button
        class="modal__open-button video-modal-button"
        :class="buttonClass"
        @click.prevent="openModal"
    >
        <slot name="button"></slot>
    </button>

    <teleport to="body">
        <div
            v-show="modalOpen"
            ref="modal"
            role="dialog"
            class="modal modal--video"
            :class="customClass"
        >
            <div
                v-show="modalOpen"
                class="modal__mask"
                @click="closeModal"
            ></div>

            <div
                class="modal__body"
            >
                <div class="modal__content">
                    <button
                        class="
                            button
                            button--circle
                            button--text-left
                            modal__close-button
                            modal__close-button--bottom
                        "
                        aria-label="Close modal"
                        @click="closeModal"
                    >
                        <span class="button__text">Close</span>
                        <span class="button__circle">
                            <UseIcon name="close" />
                        </span>
                    </button>

                    <h2 class="modal__title">
                        <slot></slot>
                    </h2>

                    <VideoPlayer
                        :video-name="videoName"
                        :subtitles-file="subtitlesFile"
                        :show-controls="showControls"
                        :closed-modal="closedModal"
                        :opened-modal="openedModal"
                    />
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import AnalyticsService, { ANALYTICS_EVENT_TYPES } from '@/services/analytics';

export default {
    props: {
        customClass: {
            type: String,
            default: ''
        },

        buttonClass: {
            type: String,
            default: ''
        },

        videoName: {
            type: String,
            required: true
        },

        subtitlesFile: {
            type: String,
            default: ''
        },

        showControls: {
            type: Boolean,
            default: true
        }
    },


    data() {
        return {
            modalOpen: false,
            closedModal: false,
            openedModal: false
        };
    },

    methods: {
        closeModal() {
            this.modalOpen = false;
            document.body.classList.remove('no-scroll');
            this.closedModal = true;
            this.openedModal = false;

            AnalyticsService.trackEvent(ANALYTICS_EVENT_TYPES.VIDEO_MODAL_CLOSE, this.videoName);
        },

        openModal() {
            this.modalOpen = true;
            document.body.classList.add('no-scroll');
            this.closedModal = false;
            this.openedModal = true;

            AnalyticsService.trackEvent(ANALYTICS_EVENT_TYPES.VIDEO_MODAL_OPEN, this.videoName);
        }
    }
}
</script>
