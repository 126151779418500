<template>
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">

        <!--
            Add icons here
            Give each icon a unique id in the format `#icon-[name]`
        -->

        <symbol id="icon-close" viewBox="0 0 56 56">
            <path d="M42.7 13.3c-.9-.9-2.4-.9-3.3 0L28 24.7 16.6 13.3c-.9-.9-2.4-.9-3.3 0-.9.9-.9 2.4 0 3.3L24.7 28 13.3 39.4c-.9.9-.9 2.4 0 3.3s2.4.9 3.3 0L28 31.3l11.4 11.4c.9.9 2.4.9 3.3 0s.9-2.4 0-3.3L31.3 28l11.4-11.4c.9-.9.9-2.4 0-3.3z" />
        </symbol>

        <symbol id="icon-play" viewBox="0 0 56 56">
            <path d="M18.667 15.913v24.174c0 1.843 2.03 2.963 3.593 1.96L41.253 29.96c1.447-.91 1.447-3.01 0-3.943L22.26 13.953c-1.563-1.003-3.593.117-3.593 1.96Z" />
        </symbol>

        <symbol id="icon-pause" viewBox="0 0 56 56">
            <path d="M18.7 44.3c2.6 0 4.7-2.1 4.7-4.7V16.3c0-2.6-2.1-4.7-4.7-4.7S14 13.7 14 16.3v23.3c0 2.6 2.1 4.7 4.7 4.7zM32.7 16.3v23.3c0 2.6 2.1 4.7 4.7 4.7s4.7-2.1 4.7-4.7V16.3c0-2.6-2.1-4.7-4.7-4.7-2.6.1-4.7 2.2-4.7 4.7z" />
        </symbol>

        <symbol id="icon-restart" viewBox="0 0 56 56">
            <path d="M41.2 14.8c-3.4-3.4-8-5.5-13.2-5.5C17.7 9.3 9.4 17.7 9.4 28c0 10.3 8.3 18.7 18.6 18.7 8.7 0 16-6 18-14h-4.9c-1.8 5.4-7 9.3-13.1 9.3-7.7 0-14-6.3-14-14s6.3-14 14-14c3.9 0 7.3 1.6 9.8 4.2l-7.5 7.5h16.3V9.3l-5.4 5.5z" />
        </symbol>

        <symbol id="icon-mute" viewBox="0 0 56 56">
            <path d="M7 23.3v9.3C7 34 8.1 35 9.3 35h7l7.7 7.7c1.5 1.5 4 .4 4-1.7V15c0-2.1-2.5-3.1-4-1.7L16.3 21h-7C8.1 21 7 22.1 7 23.3zM38.5 28c0-4.1-2.4-7.7-5.8-9.4v18.8c3.4-1.7 5.8-5.3 5.8-9.4z" /><path d="M32.7 10.4v.5c0 .9.6 1.7 1.4 2 6 2.4 10.3 8.3 10.3 15.2s-4.2 12.8-10.3 15.2c-.8.3-1.4 1.1-1.4 2v.5c0 1.5 1.5 2.5 2.8 2C43.4 44.6 49 37 49 28S43.4 11.4 35.5 8.4c-1.4-.5-2.8.5-2.8 2z" />
        </symbol>

        <symbol id="icon-unmute" viewBox="0 0 56 56">
            <path d="M9 8.5c-.9.9-.9 2.4 0 3.3l8.5 8.5-.7.7h-7c-1.3 0-2.3 1.1-2.3 2.3v9.3C7.5 34 8.6 35 9.8 35h7l7.7 7.7c1.5 1.5 4 .4 4-1.7v-9.7l9.8 9.8c-1.1.9-2.4 1.6-3.7 2.1-.8.3-1.4 1.2-1.4 2.1 0 1.7 1.7 2.8 3.2 2.1 1.9-.8 3.6-1.8 5.2-3.1l3.1 3.1c.9.9 2.4.9 3.3 0s.9-2.4 0-3.3L12.3 8.5c-.9-.9-2.4-.9-3.3 0zM44.8 28c0 1.9-.4 3.8-1 5.5l3.6 3.6c1.3-2.7 2.1-5.8 2.1-9 0-8.9-5.6-16.6-13.5-19.6-1.4-.5-2.8.5-2.8 2v.4c0 .9.6 1.7 1.4 2 6 2.4 10.2 8.2 10.2 15.1zM24.5 13.3l-.4.4 4.4 4.4V15c0-2.1-2.5-3.1-4-1.7zM39 28c0-4.1-2.4-7.7-5.8-9.4v4.2l5.8 5.8V28z" />
        </symbol>

        <symbol id="icon-subtitles" viewBox="0 0 56 56">
            <path d="M47.2 9.3H9.8c-2.6 0-4.7 2.1-4.7 4.7v28c0 2.6 2.1 4.7 4.7 4.7h37.3c2.6 0 4.7-2.1 4.7-4.7V14c0-2.6-2.1-4.7-4.6-4.7zm0 32.7H9.8V14h37.3v28z" /><path d="M14.5 23.3h4.7V28h-4.7zM14.5 32.7h18.7v4.7H14.5zM37.8 32.7h4.7v4.7h-4.7zM23.8 23.3h18.7V28H23.8z" />
        </symbol>

        <symbol id="icon-subtitles-off" viewBox="0 0 56 56">
            <path d="M46.7 9.3H15.9l4.7 4.7h26.1v26.1l4.1 4.1c.3-.7.6-1.4.6-2.2V14c-.1-2.6-2.2-4.7-4.7-4.7z" /><path d="M42 23.3H29.9l4.7 4.7H42v-4.7zM2.4 9l2.8 2.8c-.4.7-.6 1.4-.6 2.2v28c0 2.6 2.1 4.7 4.7 4.7H40l6.9 6.9 3.3-3.3L5.7 5.7 2.4 9zm6.9 6.9 7.4 7.4H14V28h4.7v-2.7l7.4 7.4H14v4.7h16.7l4.7 4.7H9.3V15.9z" />
        </symbol>

        <!-- Don't remove this is required for the launcher pages -->
        <symbol id="icon-chevron" viewBox="0 0 8 12">
            <path d="M7.41 10.59L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.59Z" />
        </symbol>
    </svg>
</template>

<script>
export default {
    name: 'IconSet'
}
</script>
