import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import UseIcon from '@/components/UseIcon';
import PageSpacer from '@/components/PageSpacer';
import PopupModal from '@/components/PopupModal';
import VideoModal from '@/components/VideoModal';
import VideoPlayer from '@/components/VideoPlayer';
import TimeoutModal from '@/components/TimeoutModal';
import { createMetaManager } from 'vue-meta';
import emitter from '@/services/eventBus';
import store from './store';

const app = createApp(App);
app.config.globalProperties.emitter = emitter;

// Global error handler
app.config.errorHandler = (err, instance, info) => {

    // Handle the error globally
    console.error('Global error:', err);
    console.log('Vue instance:', instance);
    console.log('Error info:', info);

    router.push({ name: 'ErrorPage' });
};

app.use(router);
app.use(store)
app.component('UseIcon', UseIcon);
app.component('PageSpacer', PageSpacer);
app.component('PopupModal', PopupModal);
app.component('VideoModal', VideoModal);
app.component('VideoPlayer', VideoPlayer);
app.component('TimeoutModal', TimeoutModal);
app.use(createMetaManager());
app.mount('#app');
