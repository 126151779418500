<template>
    <div class="launcher launcher__grid">
        <div class="launcher__section launcher__section--launch">
            <div class="launcher__section-body">
                <h2>About this app</h2>

                <p><strong>Read carefully before launching the app</strong></p>

                <p>To get back to this screen at any time tap in the top right hand corner of the screen and press the button to return to this screen</p>

                <span class="launcher__version-info">{{ appName }} <strong>{{ appVersion }}</strong></span>
            </div>

            <router-link class="launcher__button" :to="{ name: 'StartScreen' }">
                Launch the app
            </router-link>
        </div>

        <div class="launcher__section launcher__section--analytics">
            <div class="launcher__section-body">
                <h2>Analytics</h2>

                <p><strong>Analytics will be displayed here after the session</strong></p>
            </div>

            <router-link class="launcher__button launcher__button--alt" :to="{ name: 'AnalyticsPage' }">
                View analytics
            </router-link>
        </div>
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';

export default {
    name: 'PresentationLauncher',

    setup () {
        useMeta({
            title: 'Presentation Launcher'
        })
    },

    data () {
        return {
            appVersion: 'v' + process.env.VUE_APP_VERSION,
            appName: process.env.VUE_APP_NAME
        }
    },
}
</script>
