export const QUESTION_TYPES = {
    SINGLE_SELECT: 'single-select',
    MULTI_SELECT: 'multi-select',
    RANKED: 'ranked'
};

export const questions = [
    {
        id: 0,
        title: 'The pathophysiology of IgA nephropathy as an autoimmune disease is associated with elevated circulating levels of ______?',
        instructions: 'Select one option from below',
        instructionsOnSubmit: '',
        tag: 'Q1.',
        type: QUESTION_TYPES.SINGLE_SELECT,
        options: [
            'Gd-IgA1',
            'IgA',
            'BAFF',
            'IgM',
        ],
        answer: 'Gd-IgA1',
        showPercentageOnSubmit: false,
        info: {
            title: 'IgA Nephropathy Disease Mechanism¹',
            videoPath: 'videos/IgA_nephropathy_disease_mechanism.mp4',
            videoCaption: 'Reference: Rajasekaran A, Julian BA, Rizk DV. IgA nephropathy: an interesting autoimmune kidney disease. Am J Med Sci. 2021;361(2):176-194',
            qrCodePath: 'images/qr_q1.png',
            qrCodeText: '<p><strong>IgA Nephropathy Disease Mechanism</strong></p><br /><p>IgA nephrophathy, or IgAN, is an autoimmune disease that affects the kidneys, but may begin in the gut</p>'
        }
    },
    {
        id: 1,
        title: 'What level of proteinuria indicates risk for kidney function loss?',
        instructions: 'Select one option from below',
        instructionsOnSubmit: '',
        tag: 'Q2.',
        type: QUESTION_TYPES.SINGLE_SELECT,
        options: [
            '<0.5 g/d',
            '0.5 - 1 g/d',
            '>1.0 g/d',
            'All of the above',
        ],
        answer: 'All of the above',
        showPercentageOnSubmit: false,
        info: {
            title: 'RaDaR Study²',
            videoPath: 'videos/RaDaR_Booth.mp4',
            videoCaption: 'Reference: Pitcher, D., Braddon, F., Hendry, B., Mercer, A., Osmaston, K., Saleem, M.A., Steenkamp, R., Wong, K., Turner, A.N., Wang, K. and Gale, D.P., 2023. Long-term outcomes in IgA nephropathy. Clinical Journal of the American Society of Nephrology, 18(6), pp.727-738',
            qrCodePath: 'images/qr_q2.png',
            qrCodeText: '<strong>Overview of RaDaR Registry and Implications for IgAN Management</strong>'
        }
    },
    {
        id: 2,
        title: 'Arrange the 4-Hit pathophysiological factors in the order they are known to contribute to IgAN?',
        instructions: 'Drag and drop',
        instructionsOnSubmit: '',
        tag: 'Q3.',
        type: QUESTION_TYPES.RANKED,
        options: [
            'IgG & IgA autoantibody formation',
            'Immune complex deposition',
            'IgA Immune complexes formation',
            'Elevated levels of Gd-IgA1',
        ],
        answer: ['Elevated levels of Gd-IgA1', 'IgG & IgA autoantibody formation', 'IgA Immune complexes formation', 'Immune complex deposition'],
        showPercentageOnSubmit: false,
        info: {
            title: 'The 4 Hits of IgA Nephropathy³',
            videoPath: 'videos/4_hits_if_IgA_nephropathy.mp4',
            videoCaption: 'Reference: Rajasekaran A, Julian BA, Rizk DV. IgA nephropathy: an interesting autoimmune kidney disease. Am J Med Sci. 2021;361(2):176-194',
            qrCodePath: 'images/qr_q3.png',
            qrCodeText: '<p>It is thought that the mechanism by which IgA nephropathy develops is through a series of 4 consecutive “hits” that ultimately lead to kidney damage.</p><br /><p>Learn more about the pathogenesis of IgAN through this animated video narrated by IgANexus faculty Professor Jonathan Barratt.</p>'
        }
    },
    {
        id: 3,
        title: 'Which biomarker(s) would you use to help follow IgAN disease progression and response to treatment?',
        instructions: 'Choose any or all options, and tap submit to see how your peers responded',
        instructionsOnSubmit: 'This is how your peers responded',
        tag: 'Q4.',
        type: QUESTION_TYPES.MULTI_SELECT,
        options: [
            'Hematuria',
            'Proteinuria',
            'Ethnic Population',
            'Albuminuria',
            'Respiratory Infection',
            'Kidney Biopsy',
            'Gd-IgA1',
            'eGFR',
        ],
        answer: null,
        showPercentageOnSubmit: true,
        info: {
            title: 'Biomarkers in IgAN⁴',
            videoPath: 'videos/Biomarkers_Booth.mp4',
            videoCaption: 'Reference: Rajasekaran A, Julian BA, Rizk DV. IgA nephropathy: an interesting autoimmune kidney disease. Am J Med Sci. 2021;361(2):176-194',
            qrCodePath: 'images/qr_q4.png',
            qrCodeText: '<p>The potential use of biomarkers for the diagnosis, prognosis, and management of IgAN is emerging.</p><br /></><p>Learn more as IgANexus faculty Dr Jonathan Barratt and Dr Dana Rizk discuss the role of biomarkers in clinical practice.</p>'
        }
    },
    {
        id: 4,
        title: 'In IgAN, what is the most prominent source of gd-IgA1?',
        instructions: 'Select one option from below',
        instructionsOnSubmit: '',
        tag: 'Q5.',
        type: QUESTION_TYPES.SINGLE_SELECT,
        options: [
            'Respiratory lymphoid tissue',
            'Bone marrow',
            'Gut-associated lymphoid tissue',
            'Kidneys',
        ],
        answer: 'Gut-associated lymphoid tissue',
        showPercentageOnSubmit: false,
        info: {
            title: 'Galactose-Deficient IgA Immune Complexes⁵',
            videoPath: 'videos/Galactose_deficient_IgA_Booth.mp4',
            videoCaption: 'Reference: 1. Boyd JK, Cheung CK, Molyneux K, Feehally J, Barratt J. Kidney Int. 2012;81(9):833-843. 2. Kwon CS, Daniele P, Forsythe A, Ngai C. A systematic literature review of the epidemiology, health-related quality of life impact, and economic burden of immunoglobulin A nephropathy. J Health Econ Outcomes Res. 2021;8(2):36-45. 3. Rajasekaran A, Julian BA, Rizk DV. IgA nephropathy: an interesting autoimmune kidney disease. Am J Med Sci. 2021;361(2):176-194',
            qrCodePath: 'images/qr_q5.png',
            qrCodeText: '<p><strong>Galactose-Deficient IgA Immune Complexes<sup>5</sup></strong></p>'
        }
    },
];