<template>
    <div class="question-screen">
        <PageSpacer height="5vw" />

        <SurveyQuestion
            :question="currentQuestion"
            :readonly="true"
        />

        <div class="question-screen__footer">
            <h4 class="question-screen__post-text">
                Select your answer on the iPad below
            </h4>
        </div>
    </div>
</template>

<script>
import { questions } from '@/questions';
import SurveyQuestion from '@/components/questions/SurveyQuestion.vue';

export default {
    name: 'QuestionScreen',
    components: {
        SurveyQuestion
    },
    computed: {
        questionIndex() {
            return this.$route.params.questionIndex || 0;
        },
        currentQuestion() {
            return questions[this.questionIndex];
        },
    },
}
</script>