<template>
    <div
        id="launcher"
        ref="analytics"
        class="launcher launcher--analytics"
    >
        <h1>{{ appName }}: Top level Metrics</h1>

        <div class="launcher__stats">
            <p class="launcher__stat">
                <img src="@/assets/images/launcher/people.png" alt="" class="launcher__stat-image" />
                <span class="launcher__stat-title">Total unique sessions</span>
                <span class="launcher__stat-number">{{ totalSessions }}</span>
            </p>

            <p class="launcher__stat">
                <img src="@/assets/images/launcher/gesture-tap.png" alt="" class="launcher__stat-image" />
                <span class="launcher__stat-title">Total clicks</span>
                <span class="launcher__stat-number">{{ totalClicks }}</span>
            </p>
            <p class="launcher__stat">
                <img src="@/assets/images/launcher/document-search.png" alt="" class="launcher__stat-image" />

                <span class="launcher__stat-title">Total page views</span>
                <span class="launcher__stat-number">{{ pageViewCount }}</span>
            </p>
        </div>

        <div class="overview">
            <div class="overview__section">
                <div class="overview__header">
                    <img src="@/assets/images/launcher/document-2.png" alt="" class="overview__image" />

                    <div class="overview__title">
                        Most visited content
                    </div>
                </div>
                <div class="overview__content">
                    <ol>
                        <li v-for="(content, index) in mostVisitedContent" :key="index">
                            {{ content.url }} - <span class="overview__content--total">{{ content.count }}</span>
                        </li>
                    </ol>
                </div>
            </div>
            <div class="overview__section">
                <div class="overview__header">
                    <img src="@/assets/images/launcher/gesture-tap.png" alt="" class="overview__image" />

                    <div class="overview__title">
                        Screen interactions per day
                    </div>
                </div>
                <div class="overview__content">
                    <ol>
                        <li v-for="(interactionCount, index) in interactionsPerDay" :key="index">
                            {{ convertDate(index) }} - <span class="overview__content--total">{{ interactionCount }}</span>
                        </li>
                    </ol>
                </div>
            </div>

            <div class="overview__section">
                <div class="overview__header">
                    <img src="@/assets/images/launcher/calendar.png" alt="" class="overview__image" />

                    <div class="overview__title">
                        User sessions per day
                    </div>
                </div>
                <div class="overview__content">
                    <ol>
                        <li v-for="(dayCount, index) in userSessionPerDay" :key="index">
                            {{ convertDate(index) }} - <span class="overview__content--total">{{ dayCount }}</span>
                        </li>
                    </ol>
                </div>
            </div>

            <div class="overview__section">
                <div class="overview__header">
                    <img src="@/assets/images/launcher/clock.png" alt="" class="overview__image" />
                    <div class="overview__title">
                        Avg time spent per session
                    </div>
                </div>
                <div class="overview__content">
                    <span class="overview__stat-number">{{ avgTimeSpent }}</span>
                </div>
            </div>
        </div>

        <div class="launcher__button-wrapper">
            <button class="launcher__button" @click="$router.back();">
                <UseIcon name="chevron" />
                Home
            </button>
            <button class="launcher__button" @click="generateZIP">
                Export ZIP
            </button>
        </div>
    </div>
</template>

<script>
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import AnalyticsService, { ANALYTICS_EVENT_TYPES } from '@/services/analytics';

export default {
    name: 'AnalyticsPage',

    setup () {
        function formatDateTime(val) {
            return new Date(val).toLocaleString();
        }

        function timeSpent(previous, next) {
            let eventsDatesDiff = Math.abs(new Date(next) - new Date(previous)) / 1000;
            let minutes = Math.floor(eventsDatesDiff / 60) % 60;
            let seconds = (eventsDatesDiff % 60).toFixed(2);
            return minutes + 'm' + ' ' + seconds + 's';
        }

        return { formatDateTime, timeSpent }
    },
    data () {
        return {
            appName: process.env.VUE_APP_TITLE,
            analyticsSessions: AnalyticsService.congressStorageData,
            csvFilename: 'analytics_data_congress.csv',
            csv: [],
            printClass: '',
            // CHANGE THIS FOR SETTINGS PURPOSES
            isUHDHD: true,
            orientation: 'landscape',
            pageDimensions: [3840, 2160]
        }
    },

    computed: {
        totalSessions () {
            return Object.keys(this.analyticsSessions).length;
        },

        totalClicks () {
            let eventCount = 0;

            for (const sessionId in this.analyticsSessions) {
                const session = this.analyticsSessions[sessionId];
                eventCount += session.events.length;
            }

            return eventCount;
        },

        avgTimeSpent () {
            const averages = {};

            for (const session in this.analyticsSessions) {
                const events = this.analyticsSessions[session].events;
                let totalDuration = 0;
                const eventCount = events.length;

                // Iterate over each event in the session
                for (const event of events) {
                    const eventTime = new Date(event.time).getTime();
                    totalDuration += eventTime;
                }

                // Calculate the average duration per event
                const averageDuration = eventCount > 0 ? totalDuration / eventCount : 0;

                // Store the average duration per session
                averages[session] = averageDuration;
            }

            const sum = Object.values(averages).reduce((acc, value) => acc + value, 0);
            const count = Object.keys(averages).length;

            const totalMilliseconds = count > 0 ? sum / count : 0;

            return Math.floor((totalMilliseconds / 1000) % 60)  +'secs';
        },

        pageViewCount () {
            let pageviewCount = 0;

            for (const sessionKey in this.analyticsSessions) {
                const session = this.analyticsSessions[sessionKey];
                const events = session.events;

                for (const event of events) {
                    if (event.type === 'pageview') {
                        pageviewCount++;
                    }
                }
            }

            return pageviewCount;
        },

        userSessionPerDay () {
            const sessionDates = {};

            for (const session in this.analyticsSessions) {
                const date = new Date(this.analyticsSessions[session].date).toDateString();
                if (sessionDates[date]) {
                    sessionDates[date]++;
                } else {
                    sessionDates[date] = 1;
                }
            }
            return sessionDates;
        },

        interactionsPerDay () {
            const eventsPerSession = {};

            for (const sessionId in this.analyticsSessions) {
                const session = this.analyticsSessions[sessionId];
                const sessionDate = new Date(session.date).toDateString();
                const eventCount = session.events.length;
  
                if (eventsPerSession[sessionDate]) {
                    eventsPerSession[sessionDate] += eventCount;
                } else {
                    eventsPerSession[sessionDate] = eventCount;
                }
            }

            return eventsPerSession;

        },

        mostVisitedContent () {
            let urlCount = {};

            for (const sessionKey in this.analyticsSessions) {
                const session = this.analyticsSessions[sessionKey];
                const events = session.events;

                for (const event of events) {
                    const url = event.url;

                    // skip event without url
                    if (!url) {
                        continue;
                    }

                    if (urlCount[url]) {
                        urlCount[url]++;
                    } else {
                        urlCount[url] = 1;
                    }
                }
            }

            let sortedUrls = Object.keys(urlCount).sort((a, b) => urlCount[b] - urlCount[a]);

            let top5Urls = [];

            for (const url of sortedUrls) {
                top5Urls.push({url: url, count: urlCount[url]});
            }

            return top5Urls.slice(0, 5) 
        }
    },

    methods: {
        exportTableToCSV () {
            let rows = this.$el.querySelectorAll('tr');
            // Construct CSV file
            for (var i = 0; i < rows.length; i++) {
                var row = [], cols = rows[i].querySelectorAll('td, th');
                for (var j = 0; j < cols.length; j++)
                    row.push('"' + cols[j].innerText + '"');
                this.csv.push(row.join(','));
            }
            // Download CSV file
            this.downloadCSV(this.csv.join('\n'), this.csvFilename);
        },

        generateAnalyticsTable(eventTypes, headers, values) {
            const rows = [];

            for (const sessionKey in this.analyticsSessions) {
                const session = this.analyticsSessions[sessionKey];
                const sessionId = sessionKey;

                for (const event of session.events) {
                    if (!eventTypes.includes(event.type)) {
                        continue;
                    }

                    const row = [
                        sessionId,
                        ...values.map(value => Array.isArray(event[value]) ? event[value].join(';') : event[value])
                    ];
                    rows.push(row);
                }
            }

            const csvRows = [['Session ID', ...headers], ...rows].map(row => row.join(',')).join('\n');

            return csvRows;
        },

        generatePageviewsTable () {
            const eventTypes = [
                ANALYTICS_EVENT_TYPES.PAGEVIEW,
                ANALYTICS_EVENT_TYPES.MODAL_CLOSE,
                ANALYTICS_EVENT_TYPES.MODAL_OPEN,
                ANALYTICS_EVENT_TYPES.VIDEO_PLAY,
                ANALYTICS_EVENT_TYPES.VIDEO_PAUSE,
                ANALYTICS_EVENT_TYPES.VIDEO_RESTART,
                ANALYTICS_EVENT_TYPES.VIDEO_MODAL_OPEN,
                ANALYTICS_EVENT_TYPES.VIDEO_MODAL_CLOSE
            ];
            const headers = ['Event Type', 'URL', 'Event Time'];
            const values = ['type', 'url', 'time'];

            return this.generateAnalyticsTable(eventTypes, headers, values);
        },

        generateQuestionResponsesTable () {
            const eventTypes = [
                ANALYTICS_EVENT_TYPES.QUESTION_RESPONSE
            ];
            const headers = ['Event Type', 'Question ID', 'Question Title', 'Answer', 'Correct', 'URL', 'Event Time'];
            const values = ['type', 'questionId', 'questionTitle', 'answer', 'correct', 'url', 'time'];

            return this.generateAnalyticsTable(eventTypes, headers, values);
        },
        generateSurveyFeedbackTable () {
            const eventTypes = [
                ANALYTICS_EVENT_TYPES.SURVEY_FEEDBACK
            ];
            const headers = ['Event Type', 'Rating','URL', 'Event Time'];
            const values = ['type', 'rating', 'url', 'time'];

            return this.generateAnalyticsTable(eventTypes, headers, values);
        },

        convertDate (date) {
            return date.split('-').reverse().join('-');
        },

        
        async generateZIP() {
            const zip = new JSZip();

            // Add files to the zip

            //csv
            const pageviewsCsvContent = this.generatePageviewsTable();
            const pageviewsCsvBlob = new Blob([pageviewsCsvContent], { type: 'text/csv' });
            zip.file('pageviews.csv', pageviewsCsvBlob);

            const responsesCsvContent = this.generateQuestionResponsesTable();
            const responsesCsvBlob = new Blob([responsesCsvContent], { type: 'text/csv' });
            zip.file('responses.csv', responsesCsvBlob);

            const feedbackCsvContent = this.generateSurveyFeedbackTable();
            const feedbackCsvBlob = new Blob([feedbackCsvContent], { type: 'text/csv' });
            zip.file('feedback.csv', feedbackCsvBlob);

            // Generate the zip file
            const zipContent = await zip.generateAsync({ type: 'blob' });

            // Save the zip file
            saveAs(zipContent, 'files.zip');
        }
    }
}
</script>

