<template>
    <div class="secret-button" :class="'secret-button--' + position">
        <!--
            Button can be positioned in each of the corners by adding the correct class
            Make sure you update the instructions on the launcher page if you change the position
        -->
        <button
            class="secret-button__button"
            @click="showLauncherButton"
        ></button>

        <router-link v-if="showLauncherPage" class="launcher__button secret-button__return-link" :to="{ name: 'PresentationLauncher' }">
            Back to launcher - {{ selectedRoom }}
        </router-link>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {

    props: {
        position: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            showLauncherPage: process.env.VUE_APP_SHOW_LAUNCHER_PAGE
        }
    },

    computed: {
        ...mapGetters(['selectedRoom']),
    },

    methods: {
        showLauncherButton () {
            document.body.classList.add('show-launcher-button');
            setTimeout(() => {
                document.body.classList.remove('show-launcher-button')
            }, 3000)
        }
    }
}
</script>
