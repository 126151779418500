import router, { APP_MODE } from '@/router';
import { io } from 'socket.io-client';
import emitter from '@/services/eventBus';
import store from '@/store';

class SocketioService {
    socket;

    constructor() {}

    setupSocketConnection() {
        this.socket = io(process.env.VUE_APP_SERVER_URL, {});

        this.socket.emit('init', {
            mode: APP_MODE,
            roomId: store.getters['selectedRoom']
        });

        switch (APP_MODE) {
        case 'dispatcher':
            this.socket.on('video-active', () => {
                emitter.emit('video-active');
            });

            this.socket.on('video-inactive', () => {
                emitter.emit('video-inactive');
            })
            break;
        case 'receiver':
            this.socket.on('available-rooms', (rooms) => {
                store.dispatch('setAvailableRooms', rooms);
            });

            this.socket.on('start', () => {
                router.push({ name: 'StartScreen' });
            });

            this.socket.on('open-question', (questionIndex) => {
                router.push({ path: `/question/${questionIndex}` });
            });

            this.socket.on('submit-question', (questionIndex) => {
                router.push({ path: `/question-review/${questionIndex}`});
            });

            this.socket.on('restart-video', (questionIndex) => {
                const id = Math.random().toString(36).substring(2, 9);
                router.push({ path: `/question-review/${questionIndex}`, query: { restartVideoKey: id } });
            });

            this.socket.on('show-summary', () => {
                router.push({ name: 'SummaryScreen' });
            });

            // this.socket.on('room-closed', () => {
            //     router.push({ name: 'SelectRoomScreen' });
            // });
            break;
        default:
            break;
        }
    }

    start() {
        this.socket.emit('start', { roomId: store.getters['selectedRoom'] });
    }

    openQuestion(questionIndex) {
        this.socket.emit('open-question', { roomId: store.getters['selectedRoom'], questionIndex });
    }

    submitQuestion(questionIndex) {
        this.socket.emit('submit-question', { roomId: store.getters['selectedRoom'], questionIndex });
    }

    videoActive() {
        this.socket.emit('video-active', { roomId: store.getters['selectedRoom'] });
    }

    videoInactive() {
        this.socket.emit('video-inactive', { roomId: store.getters['selectedRoom'] });
    }

    restartVideo(questionIndex) {
        this.socket.emit('restart-video', { roomId: store.getters['selectedRoom'], questionIndex });
    }

    showSummary() {
        this.socket.emit('show-summary', { roomId: store.getters['selectedRoom'] });
    }

    logEvent(event) {
        this.socket.emit('log-event', event);
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}

export default new SocketioService();
