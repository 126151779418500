<template>
    <div class="video">
        <div v-if="canShowLoading" class="loading-icon"></div>
        <video
            ref="video"
            preload="metadata"
            :loop="loopVideo"
            :autoplay="autoplayEnabled"
            :muted="mutedVideo"
            :playsinline="playsInlineEnabled"
            @click="playPauseVideo"
            @ended="videoIsInactive"
            @playing="videoIsActive"
            @loadeddata="videoLoaded"
            @timeupdate="updateProgressBar"
        >
            <source :src="require(`@/assets/${videoName}`)" type="video/mp4" />
            <track
                v-if="subtitlesFile"
                :src="require(`@/assets/videos/subtitles/${subtitlesFile}`)"
                kind="subtitles"
                srclang="en"
                default
            />
        </video>
        <div
            v-if="showSeekBar"
            ref="seekBar"
            class="seekBar"
            @click="seekVideo"
        >
            <div ref="seekBarFill" class="seekBar__fill"></div>
        </div>
        <div v-if="showVideoTimings" class="videoInfo">
            <span class="videoInfo__current-time videoInfo__time">{{
                currentTime
            }}</span>
            <span class="videoInfo__time-remaining videoInfo__time">{{
                timeRemaining
            }}</span>
            <span class="videoInfo__video-length videoInfo__time">{{
                videoLength
            }}</span>
        </div>

        <div
            v-if="showControls"
            class="video__controls video__controls--bottom"
        >
            <button class="button button--circle" @click="playPauseVideo">
                <span class="button__text">{{ videoPlayingText }}</span>
                <div class="button__circle">
                    <UseIcon
                        v-if="!videoPlaying"
                        class="icon--play"
                        name="play"
                    ></UseIcon>
                    <UseIcon v-else class="icon--pause" name="pause"></UseIcon>
                </div>
            </button>

            <button
                class="button button--circle"
                aria-label="Restart video"
                @click="restartVideo"
            >
                <span class="button__text">Restart</span>
                <div class="button__circle">
                    <UseIcon name="restart" />
                </div>
            </button>

            <button
                class="button button--circle"
                :class="muteLabel"
                @click="muteVideo"
            >
                <span class="button__text">{{ muteLabel }}</span>
                <div class="button__circle">
                    <UseIcon
                        v-if="!mutedVideo"
                        class="icon--mute"
                        name="mute"
                    ></UseIcon>
                    <UseIcon
                        v-else
                        class="icon--unmute"
                        name="unmute"
                    ></UseIcon>
                </div>
            </button>

            <button
                v-if="subtitlesFile"
                class="button button--circle"
                @click="toggleSubtitles"
            >
                <span class="button__text">Subtitles</span>
                <div class="button__circle">
                    <UseIcon
                        v-if="!showSubtitles"
                        class="icon--subtitles"
                        name="subtitles"
                    ></UseIcon>
                    <UseIcon
                        v-else
                        class="icon--subtitles-off"
                        name="subtitles-off"
                    ></UseIcon>
                </div>
            </button>
        </div>
    </div>
</template>

<script>
import AnalyticsService, { ANALYTICS_EVENT_TYPES } from '@/services/analytics';
import SocketioService from '@/services/socketio';

export default {
    props: {
        videoName: {
            type: String,
            required: true
        },

        subtitlesFile: {
            type: String,
            default: ''
        },

        showControls: {
            type: Boolean,
            default: false
        },

        autoplayEnabled: {
            type: Boolean,
            default: true
        },

        closedModal: {
            type: Boolean,
            default: false
        },

        openedModal: {
            type: Boolean,
            default: false
        },

        showSeekBar: {
            type: Boolean,
            default: false
        },

        loopVideo: {
            type: Boolean,
            default: false
        },

        isBgVideo: {
            type: Boolean,
            default: false
        },

        playsInlineEnabled: {
            type: Boolean,
            default: true
        },

        // This is to allow to choose if the video starts muted or not, so we can get it autoplaying
        isVideoMuted: {
            type: Boolean,
            default: true
        },

        showVideoTimings: {
            type: Boolean,
            default: true
        },

        // This allows to trigger video restart from outside of the component by updating the id
        restartVideoKey: {
            type: String,
            default: '',
        }
    },

    data() {
        return {
            videoPlaying: false,
            videoControls: true,
            mutedVideo: this.isVideoMuted,
            showSubtitles: false,
            video: '',
            videoSubtitles: '',
            loading: true,
            seekBar: '',
            seekBarFill: '',
            videoLength: '00:00',
            timeRemaining: '00:00',
            currentTime: '00:00'
        };
    },

    computed: {
        muteLabel() {
            return this.mutedVideo ? 'Unmute' : 'Mute';
        },

        videoPlayingText() {
            return this.videoPlaying ? 'Pause' : 'Play';
        },

        canShowLoading() {
            return this.loading && !this.isBgVideo ? true : false;
        }
    },

    watch: {
        closedModal(val) {
            if (val) {
                this.stopVideo();
            }
        },

        openedModal(val) {
            if (val) {
                this.video.load();
                this.playVideo();
            }
        },

        restartVideoKey() {
            if (this.restartVideoKey) {
                this.restartVideo();
            }
        }
    },

    mounted() {
        this.video = this.$refs.video;
        this.seekBar = this.$refs.seekBar;
        this.seekBarFill = this.$refs.seekBarFill;
        if (this.subtitlesFile) {
            this.videoSubtitles =
                this.video.textTracks && this.video.textTracks[0];
            this.videoSubtitles.mode = 'hidden';
        }
        this.video.addEventListener('loadedmetadata', () => {
            this.videoLength = this.formatTime(this.video.duration);
        });
    },

    unmounted() {
        // added this on unmouted so if the video is not on a modal when umounted the video inactive is actioned
        if (!this.isBgVideo) {
            this.emitter.emit('video-inactive');
            SocketioService.videoInactive();
        }
    },

    methods: {
        videoLoaded() {
            this.loading = false;
        },

        resetVideo() {
            this.video.pause();
            this.video.currentTime = 0;
        },

        restartVideo() {
            this.stopVideo();
            this.video.load();
            this.playVideo();

            // Track video restarts
            AnalyticsService.trackEvent(ANALYTICS_EVENT_TYPES.VIDEO_RESTART, this.videoName);
        },

        pauseVideo() {
            this.video.pause();
            this.videoIsInactive();

            // Track video pauses
            AnalyticsService.trackEvent(ANALYTICS_EVENT_TYPES.VIDEO_PAUSE, this.videoName);
        },

        playVideo() {
            this.video.play();
            this.videoIsActive();

            // Track video plays
            AnalyticsService.trackEvent(ANALYTICS_EVENT_TYPES.VIDEO_PLAY, this.videoName);
        },

        stopVideo() {
            this.video.pause();
            this.video.currentTime = 0;
        },

        playPauseVideo() {
            if (this.video.paused) {
                this.playVideo();
            } else {
                this.pauseVideo();
            }
        },

        muteVideo() {
            this.mutedVideo = !this.mutedVideo;
        },

        videoIsActive() {
            if (!this.isBgVideo) {
                this.emitter.emit('video-active');
                SocketioService.videoActive();
            }
            this.videoPlaying = true;
        },

        videoIsInactive() {
            if (!this.isBgVideo) {
                this.emitter.emit('video-inactive');
                SocketioService.videoInactive();
            }
            this.videoPlaying = false;
        },

        toggleSubtitles() {
            this.showSubtitles = !this.showSubtitles;
            this.videoSubtitles.mode = this.showSubtitles
                ? 'showing'
                : 'hidden';
        },
        updateProgressBar() {
            if (this.showSeekBar) {
                this.currentTime = this.formatTime(this.video.currentTime);
                const progress =
                    (this.video.currentTime / this.video.duration) * 100;
                this.seekBarFill.style.width = `${progress}%`;
                this.timeRemaining =
                    '-' +
                    this.formatTime(
                        this.video.duration - this.video.currentTime
                    );
            }
        },

        seekVideo(event) {
            const clickPosition = event.offsetX / this.seekBar.offsetWidth;
            const seekTime = clickPosition * this.video.duration;
            this.video.currentTime = seekTime;
        },

        formatTime(seconds) {
            const minutes = Math.floor(seconds / 60);
            const secondsRemaining = Math.floor(seconds % 60);
            const roundedSeconds = Math.ceil(secondsRemaining);
            return `${minutes}:${
                roundedSeconds < 10 ? '0' : ''
            }${roundedSeconds}`;
        }
    }
};
</script>
