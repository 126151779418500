<template>
    <svg class="icon">
        <use :xlink:href="'#icon-' + name" />
    </svg>
</template>

<script>
export default {
    name: 'UseIcon',
    props: {
        name: {
            type: String,
            default: ''
        }
    }
}
</script>
