<template>
    <div>404 Page not found</div>
</template>

<script>
import { useMeta } from 'vue-meta';

export default {
    name: 'NotFound',
    setup () {
        useMeta({
            title: 'Page not found'
        })
    }
}
</script>
