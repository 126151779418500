<template>
    <div>
        <metainfo>
            <template #title="{ content }">
                {{
                    content
                        ? `${selectedRoom} - ${content} - Touchscreen starter project`
                        : `${selectedRoom} - Touchscreen starter project`
                }}
            </template>
        </metainfo>

        <IconSet />

        <div
            v-if="$route.meta.presentationLauncher"
            class="layout layout--full-screen layout--launcher"
        >
            <router-view />
        </div>

        <div v-else class="layout layout--full-screen" :class="$route.name">
            <PageHeader v-if="showHeader" />
            <div v-else></div> 

            <main class="main">
                <router-view />

                <SecretButton v-if="showLauncherPage" position="top-right" />
            </main>

            <NavigationList v-if="showNavigation" />
            
            <PageFooter v-if="showFooter" />
            <div v-else></div>

            <TimeoutModal v-if="showTimeoutModal" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SecretButton from '@/launcher/SecretButton.vue';
import IconSet from '@/components/IconSet.vue';
import PageHeader from '@/components/PageHeader.vue';
import PageFooter from '@/components/PageFooter.vue';
import NavigationList from '@/components/NavigationList';
import SocketioService from '@/services/socketio';
import AnalyticsService, { ANALYTICS_EVENT_TYPES } from '@/services/analytics';
import { useMeta } from 'vue-meta';
import { APP_MODES, checkMode } from './router';

export default {
    name: 'App',

    components: {
        SecretButton,
        IconSet,
        PageHeader,
        PageFooter,
        NavigationList
    },

    setup() {
        useMeta({
            title: 'Welcome'
        });
    },

    data() {
        return {
            showLauncherPage: process.env.VUE_APP_SHOW_LAUNCHER_PAGE,
            ipadBuild: process.env.VUE_APP_IPAD_BUILD
        };
    },

    computed: {
        ...mapGetters(['selectedRoom']),
        showTimeoutModal() {
            return checkMode(APP_MODES.DISPATCHER);
        },
        showNavigation() {
            return this.$route.meta.showNavigation;
        },
        showHeader() {
            return this.$route.meta.showHeader;
        },
        showFooter() {
            return this.$route.meta.showFooter;
        },
    },

    watch: {
        $route: {
            deep: true,
            handler(to) {
                // If we are on the start page, treat it as a new session
                // NOTE - you may want to change this to whichever view you would like the sessions to reset on
                if (to.name === 'StartScreen') {
                    AnalyticsService.addNewSession();
                }

                if (
                    to.name !== 'PresentationLauncher' &&
                    to.name !== 'AnalyticsPage' &&
                    checkMode(APP_MODES.DISPATCHER) // track only dispatcher app
                ) {
                    // If we aren't on the launcher screen or analytics screen, update congressStorageData with the page view
                    AnalyticsService.trackEvent(ANALYTICS_EVENT_TYPES.PAGEVIEW, to.fullPath);
                }
            }
        }
    },

    mounted() {
        this.initRoom();
        SocketioService.setupSocketConnection();
        AnalyticsService.init();
    },
    unmounted() {
        SocketioService.disconnect();
    },
    methods: {
        ...mapActions(['initRoom', 'selectRoom'])
    }
};
</script>

<style lang="scss">
@import './src/scss/app';
</style>
