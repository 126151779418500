<template>
    <div class="question-review-screen">
        <div class="question-review-screen__video-wrapper">
            <h1 class="question-review-screen__title">
                <span v-if="currentQuestion.tag" class="question-tag__wrapper"><span class="question-tag">{{ currentQuestion.tag }}</span></span>
                {{ currentQuestion.info.title }}
            </h1>
            
            <VideoPlayer
                v-if="currentQuestion.info.videoPath"
                class="question-review-screen__video"
                :video-name="currentQuestion.info.videoPath"
                :is-video-muted="true"
                :show-video-timings="false"
                :restart-video-key="restartVideoKey"
            />
  
            <p v-if="currentQuestion.info.videoCaption" class="question-review-screen__video-caption">
                {{ currentQuestion.info.videoCaption }}
            </p>
        </div>

        <div class="qrcode-wrapper">
            <div v-if="currentQuestion.info.qrCodeText" class="qrcode-text" v-html="currentQuestion.info.qrCodeText">
            </div>

            <div v-if="currentQuestion.info.qrCodePath" class="qrcode-container">
                <div class="qrcode-icon-container">
                    <img class="qrcode-icon" src="@/assets/images/phone-qr.svg" alt="phone qr icon" />
                </div>

                <span class="qrcode-pipe"></span>

                <div class="qrcode">
                    <p class="qrcode-call-to-action-text">
                        Visit the IgANexus Website to learn more
                    </p>
                    <img class="qrcode-image" :src="require(`@/assets/${currentQuestion.info.qrCodePath}`)" alt="QR Code" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { questions } from '@/questions';

export default {
    name: 'QuestionReviewScreen',
    computed: {
        questionIndex() {
            return this.$route.params.questionIndex || 0;
        },
        currentQuestion() {
            return questions[this.questionIndex];
        },
        restartVideoKey() {
            return this.$route.query.restartVideoKey || '';
        }
    },
}
</script>