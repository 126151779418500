import { APP_MODES, checkMode } from '@/router';
import SocketioService from '@/services/socketio';
import { createStore } from 'vuex'

const store = createStore({
    state () {
        return {
            selectedRoom: null,
            availableRooms: []
        }
    },
    getters: {
        selectedRoom: (state) => state.selectedRoom,
        availableRooms: (state) => state.availableRooms
    },
    actions: {
        initRoom({ dispatch }) {
            const selectedRoom = checkMode(APP_MODES.DISPATCHER)
                ? process.env.VUE_APP_ROOM_ID || localStorage.getItem('selectedRoom') || Math.random().toString(36).substring(2, 9)
                : localStorage.getItem('selectedRoom') || null;

            dispatch('selectRoom', selectedRoom);
        },
        selectRoom({ commit }, room) {
            commit('setSelectedRoom', room);

            SocketioService.disconnect();
            room
                ? localStorage.setItem('selectedRoom', room)
                : localStorage.removeItem('selectedRoom');
            SocketioService.setupSocketConnection();
        },
        setAvailableRooms({ commit }, rooms) {
            commit('setAvailableRooms', rooms);
        }
    },
    mutations: {
        setSelectedRoom (state, room) {
            state.selectedRoom = room;
        },
        setAvailableRooms(state, rooms) {
            state.availableRooms = rooms;
        }
    }
})

export default store;