<template>
    <div class="question-title__wrapper">
        <span v-if="tag" class="question-tag__wrapper"><span class="question-tag">{{ tag }}</span></span>

        <h1 class="question-title">
            {{ title }}
        </h1>
    </div>
</template>

<script>
export default {
    name: 'QuestionTitle',
    props: {
        title: {
            type: String,
            required: true
        },
        tag: {
            type: String,
            default: ''
        }
    }
}
</script>