<template>
    <button
        class="modal__open-button modal-button"
        :class="buttonClass"
        @click.prevent="openModal"
    >
        <slot name="button"></slot>
    </button>

    <teleport to="body">
        <div
            role="dialog"
            class="modal"
            :class="customClass"
        >
            <transition name="modal-mask">
                <div
                    v-show="modalOpen"
                    class="modal__mask"
                    @click="closeModal"
                ></div>
            </transition>

            <transition name="modal-body">
                <div
                    v-show="modalOpen"
                    class="modal__body"
                >
                    <button
                        class="
                            button
                            button--circle
                            button--text-left
                            modal__close-button
                            modal__close-button--top
                        "
                        aria-label="Close modal"
                        @click="closeModal"
                    >
                        <!-- <span class="button__text">Close</span> -->
                        <span class="button__circle">
                            <UseIcon name="close" />
                        </span>
                    </button>

                    <div class="modal__content">
                        <slot></slot>
                    </div>
                </div>
            </transition>
        </div>
    </teleport>
</template>

<script>
import AnalyticsService, { ANALYTICS_EVENT_TYPES } from '@/services/analytics';

export default {
    props: {
        customClass: {
            type: String,
            default: ''
        },
        buttonClass: {
            type: String,
            default: ''
        },
        modalName: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            modalOpen: false
        };
    },
    methods: {
        closeModal () {
            this.modalOpen = false;
            document.body.classList.remove('no-scroll');

            // Track modal opens
            AnalyticsService.trackEvent(ANALYTICS_EVENT_TYPES.MODAL_CLOSE, this.modalName);
        },
        openModal () {
            this.modalOpen = true;
            document.body.classList.add('no-scroll');

            // Track modal opens
            AnalyticsService.trackEvent(ANALYTICS_EVENT_TYPES.MODAL_OPEN, this.modalName);

        },
    },
}
</script>
